<template>
	<CommonPage>
		<div class="index">
			<Wrapper color="#121212">
				<div class="top">
					<h1>{{ domainConfig.title }}</h1>
					<Ad :ads="adsensConfig.ad1"></Ad>
					<div class="date">
						{{ `${month} | ${day}` }}
					</div>
				</div>
			</Wrapper>
			<component
				:is="contentComponent"
				:curOne="curOne" />
			<Wrapper color="#ffffff">
				<div class="b">
					<div class="son">
						<Ad :ads="adsensConfig.ad2"></Ad>

						<div class="text">Related Content</div>
						<div class="hs">
							<div
								@click="$router.push({name: 'detail', params: { id: item?.id, title: item.title.toLocaleLowerCase().replaceAll(' ', '-') }})"
								v-for="item in list"
								:key="item?.id"
								class="itemBlock pointer"
								:style="{ background: `url(${item?.thumbnailImgUrl}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }">
								<div class="e"></div>
								<div class="num">{{ item?.category }}</div>
								<div class="title">
									{{ item?.title }}
								</div>
							</div>
							<Ad
								:ads="adsensConfig.ad3"
								:posi="{
									pc: {
										rowAt: 3,
										colNum: 3,
									},
									m: 3
								}"></Ad>
						</div>
						<div class="view-all-btn" @click="$router.push({ name: 'all-list' })">View All</div>
					</div>
				</div>
			</Wrapper>
		</div>
	</CommonPage>
</template>
<script>
import { getList } from '../api'
const _m = () => import('@/pages/components/bannerM.vue') //代码切割
const _pc = () => import('@/pages/components/bannerPC.vue') //代码切割
import '@/css/index.scss'
export default {
	metaInfo () {
		return {
		title: this.title,
		meta: [
			{
				name: 'description',
				content: this.description,
			},
			{
				name: 'title',
				content: this.title,
			},
			{
				property: 'og:title',
				content: this.title,
			},
			{
				property: 'og:description',
				content: this.description,
			},
		],
	}
	},
	data() {
		return {
			month: '',
			day: '',
			contentComponent: null,
			posi: '',
			quoteEng: '',
			curOne: {},
			list: []
		}
	},
	computed: {
		title () {
			return `Home | ${this.domainConfig['title']}`
		},
		description () {
			return this.domainConfig.description
		}
	},
	async activated() {
		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	created() {
		if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
			this.contentComponent = _pc
			return
		}
		this.contentComponent = _m
	},
	async beforeMount() {
		const res = await getList({
			type: this.domainConfig['type'],
			pageNum: 1,
			pageSize: 30
		})
		console.log('🚀🚀🚀 res: ', res)
		let list = res.popularizations
		list.map((item) => {
			return {
				id: item.id,
				title: item.title,
				type: item.type,
				url: item.thumbnailImgUrl || this.getBlankImg(),
				category: item.category,
				summary: item.summary,
			}
		})
		this.list = list
		// this.$store.state.list = list
		this.curOne = list[0]

		const today = new Date()
		// 定义月份名称数组
		const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

		// 获取月份和日期
		const month = monthNames[today.getMonth()]
		const day = today.getDate()
		this.month = month
		this.day = day
	},
	async mounted() {
	},
	methods: {
		getBlankImg() {
			let randomNum = Math.floor(Math.random() * 9) + 1
			// console.log(require(`@/assets/i${randomNum}.webp`));
			return require(`@/assets/i${randomNum}.webp`)
		},

	}
}
</script>